@import '_incs/_mixins';
@import '_incs/_variable';

body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    background: #fff;
    min-height: 100vh;
    position: relative;
}
.wrapper {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}
p {
    text-align: left;
}
h2.title {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;

    @include bp($scr--phablet) {
        font-size: 15px;
        font-weight: 500;
    }
}
h2.noteTitle {
    font-weight: 600;
    font-size: 14px;
}
p.note {
    text-align: center;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

svg {
    overflow: hidden;
    width: 100% !important;
    vertical-align: top !important;
}

.topbar {
    background: #121a36;
    text-align: center;
    color: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #f3f3f3;
}

.note-container {
    background: #fff;
    border-radius: 25px;
    margin: 0 auto;
    font-family: 'Work Sans', sans-serif;
    padding-bottom: 40px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form input {
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #dbe2e8;
    font-size: 14px;
    border-radius: 10px;
    color: #636363;
    box-shadow: 0 2px 2px 0 rgba(46, 60, 73, 0.05);
    outline: none;
    width: 100%;
}
.updateForm textarea {
    width: 100%;
    min-height: 200px;
    margin: 0 0 1rem 0;
    padding: 15px 15px 0 15px;
    font-size: 14px;
    border: 1px solid #dbe2e8;
    border-color: #121a36 !important;
    box-shadow: 4px 4px 14px #eaeaea;
    border-radius: 10px;
    color: #636363;
    font-family: 'Work Sans,', sans-serif;
    outline: none;
}
.form textarea {
    border: 1px solid #dbe2e8;
    font-size: 14px;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 100%;
    height: 80px;
    padding: 15px 15px 0 15px;
    color: #636363;
    font-family: 'Work Sans,', sans-serif;
    box-shadow: 0 2px 2px 0 rgba(46, 60, 73, 0.05);
    outline: none;
}
h2.nonotes {
    text-align: center;
    font-weight: 100;
}
.form button {
    background: #dbe2e8;
    padding: 15px 40px;
    text-align: center;
    color: #000;
    font-family: 'Work Sans', sans-serif;
    border-radius: 4px;
    border: none;
    font-size: 20px;
}
.notes-list {
    display: block;
    padding: 0;
}
.list-container {
    padding-bottom: 2.5rem;
}
.accordion {
    width: 100%;
}

.card-header {
    background-color: #fff !important;
    border-left: 5px solid #121a36;
    height: 80px;
}

.card-body {
    padding: 20px 10px 20px 20px !important;
    text-align: center;
    float: left;
    display: block;
    width: 100%;
}

h2.date {
    margin-top: -3%;
    margin-bottom: 0%;
}

time {
    font-size: 8px;
    font-weight: 500;
}
.noteButtons {
    position: absolute;
    right: 0;
    top: 20px;
}
.editButtons {
    float: right;
    display: block;
}
.updateButtons {
    margin: 0 2rem;
}

.noteBody {
    margin-top: 1rem;
    float: left;
    text-align: justify;
    clear: both;
    white-space: pre-line;
}
.editIcon {
    width: 20px !important;
    height: 25px !important;
}
button.update {
    color: #fff;
    font-size: 15px;
    margin: 1rem 0;
    height: 25px;
    width: 100px;
    background-color: #121a36;
    border-radius: 5px;
    border: 0;
    &:focus {
        outline: none;
    }
}

button {
    margin-right: 5px;
    height: 30px;
    width: 30px;
    background-color: #121a36;
    border-radius: 5px !important;
    border: 0;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    &.print {
        background-image: url('../icons/icons_print.svg');
        margin-right: 10px;
        @include bp($scr--phablet, max-width) {
            display: none;
        }
    }
    &.delete {
        margin-right: 10px;
        background-image: url('../icons/icons_delete.svg');
    }
    &.edit {
        background-image: url('../icons/icons_edit.svg');
        &:focus {
            outline: none;
        }
    }

    &.delete:hover,
    &.edit:hover,
    &.print:hover,
    &.update:hover {
        background-color: #dc6b2f;
    }
}

.displayButton {
    display: inline-block;
}
input.submit {
    width: 125px !important;
    background-color: #121a36;
    color: #fff;
    font-weight: 700;
    &:focus {
        border: none !important;
    }
}
input.submit:hover {
    background-color: #dc6b2f;
    font-weight: 700;
    box-shadow: 0px 0px 15px 0px rgba(46, 60, 73, 0.35);
}
.form input:focus {
    border-color: #121a36 !important;
    box-shadow: 4px 4px 14px #eaeaea;
}
.form textarea:focus {
    border: 1px solid #dbe2e8;
    border-color: #121a36 !important;
    box-shadow: 4px 4px 14px #eaeaea;
}

.printStyle {
    padding: 50px;
    p {
        white-space: pre-line;
    }
}

.alert {
    position: fixed !important;
    bottom: 10px !important;
    right: 15px !important;
    z-index: 999;
}

.inputWrapper {
    position: absolute;
    left: 20px;
    top: 18px;
    width: calc(100% - 120px);
    input {
        border: 1px solid #121a36;
        padding: 5px;
        border-radius: 5px;
        width: 100%;
        &:focus {
            outline: none;
        }
    }
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 2000000;
}

.modal-dialog {
    width: 100%;
    z-index: 100000;
}

.btn {
    width: 45%;
    line-height: 1.8 !important;
    padding-top: 0 !important;
}
.btn-primary {
    color: #fff;
    background-color: #121a36 !important;
    border-color: #121a36 !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: #dc6b2f !important;
    border-color: #dc6b2f !important;
}
.btn-secondary {
    color: #43273b;
    background-color: #6c757d00;
    border: 1px solid #43273b;
}
.modal-header .close {
    margin: 0 !important;
    width: auto;
    height: auto;
    padding: 0 !important;
    &:focus {
        outline: none;
    }
}
.close {
    color: #fff !important;
    opacity: 1 !important;
    text-shadow: none !important;
}
.close:hover {
    color: #fff !important;
    text-decoration: none;
    opacity: 1 !important;
    text-shadow: none;
}
.modal-header {
    background-color: #121a36;
    color: #fff;
}
.btn-secondary {
    color: #43273b !important;
    background-color: #ffffff !important;
    border: 1px solid #43273b !important;
    &:hover {
        background-color: #eaeaea !important;
    }
}
